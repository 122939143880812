.footer {
  background-color: #222;
  color: #fff;
  padding: 20px 0;
  text-align: center;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo img {
    max-width: 150px;
    margin-bottom: 20px;
  }

  .info {
    text-align: center;
    margin-bottom: 20px;

    h3 {
      margin-bottom: 5px;
      font-size: 1.5rem;
    }

    p {
      margin: 0;

      .link {
        color: #fff;
        text-decoration: underline;

        &:hover {
          color: #ddd;
        }
      }
    }
  }

  .map {
    width: 100%;
  }

  .map iframe {
    width: 100%;
    max-width: 600px;
    height: 450px;
    border: none;
    margin-bottom: 20px;
  }
}
