.container {
  width: 90%;
  max-width: 500px;
  margin: 130px auto 0 auto;
  font-family: Arial, sans-serif;


  .menu {
    list-style: none;
    padding: 0;
    margin: 0;

    .test {
      margin-bottom: 80px;
    }

    .categoryButton {
      width: 100%;
      font-size: 1.5rem;
      text-align: left;
      background: transparent;
      color: var(--white);
      font-weight: bold;
      border: 3px solid var(--yellow);
      padding: 30px;
      cursor: pointer;
      transition: all 0.3s ease;
      margin-bottom: 10px;
    }


    .dropdown {
      list-style: none;
      padding: 0;
      margin: 0;
      overflow: hidden;
      animation: slideDown 0.3s ease-out;
      background: transparent;
      border-left: 3px solid var(--yellow);
      border-right: 3px solid var(--yellow);
      border-bottom: 3px solid var(--yellow);
      margin-bottom: 10px;
      margin-top: -10px;

      .dropdownItem {
        display: flex;
        justify-content: space-between;
        padding: 20px 30px;
        font-weight: bold;
        // border-bottom: 1px solid #eee;
        color: var(--white);
        font-size: 1.2rem;
        .itemDescription {
          font-weight: normal;
          font-size: 1rem;
        }
      }

      .dropdownItem:last-child {
        border-bottom: none;
      }

      .dropdownItem:hover {
        background: #f0f0f0;
        color: var(--darkGray);
      }

    }
  }
}


@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


@media screen and (max-width: 800px) {
  .container {
    margin-top: 100px;
  }
}