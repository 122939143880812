@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--green);
}

* {
  font-family: 'Poppins';
  --yellow: #7c7f63;
  --white: #F7F7F9;
  --green: #345945;
  --gray: #e8e4e4;
  --black: #0B0014;
  --darkGray: #302c2c;
  --red: #D44D5C;
  --blue: #888494;
}