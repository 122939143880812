.container {
  margin-top: 80px;
  padding: 2rem;
  
  background:
  linear-gradient(
    rgba(0, 0, 0, 0.9), 
    rgba(0, 0, 0, 0.8)
  ),
  url("../../images/bg3.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  color: var(--white);
  font-family: "Roboto", sans-serif;
  animation: fadeIn 1.5s ease-in-out;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
}

.header {
  text-align: center;
  margin-bottom: 2rem;

  h1 {
    font-size: 2.5rem;
    margin: 0;
    color: var(--red);
    text-transform: uppercase;
    animation: slideDown 1.2s ease-out;
  }

  p {
    font-size: 1.2rem;
    // color: #666;
  }
}

.content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.8;

  p {
    margin: 0;
  }
}

.features {
  display: flex;
  justify-content: space-around;
  margin: 2rem 0;
  flex-wrap: wrap;

  .featureItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    animation: popUp 1.2s ease-in-out;

    .emoji {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1rem;
      // color: #444;
      font-weight: bold;
    }
  }
}

.list {
  list-style: none;
  margin: 1.5rem 0;
  padding: 0;

  li {
    font-size: 1.2rem;
    margin: 0.5rem 0;
    animation: slideLeft 1s ease-out;

    &::before {
      content: "👉";
      margin-right: 0.5rem;
    }
  }
}

.ending {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--red);
  animation: pulse 1.5s infinite;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes popUp {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}
