.landingPage {
  font-family: "Arial", sans-serif;
  color: #fff;
  background:
  linear-gradient(
    rgba(0, 0, 0, 0.7), 
    rgba(0, 0, 0, 0.7)
  ),
  url("../../images/bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: 65%;

  .hero {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    .title {
      font-size: 3rem;
      font-weight: bold;
      margin-bottom: 1rem;
      text-align: center;
    }

    .subtitle {
      font-size: 1.5rem;
      margin-bottom: 2rem;
      max-width: 600px;
      text-align: center;
    }

    .ctaButton {
      background-color: var(--red);
      color: #fff;
      padding: 15px 30px;
      width: 200px;
      margin-bottom: 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 1rem;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: var(--red);
      }
    }
  }

  .features {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 50px 20px;
    background-color: var(--green);

    .feature {
      text-align: center;
      margin: 20px;
      padding: 20px;
      border-radius: 10px;
      background-color: var(--green);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      // border: 2px solid var(--yellow);
      transition: transform 0.3s ease;
      width: 90%;
      max-width: 300px;

      &:hover {
        transform: scale(1.05);
      }

      svg {
        width: 80px;
        height: 80px;
      }

      h3 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 1rem;
        color: #bdc3c7;
      }
    }
  }

  .footer {
    padding: 20px;
    background-color: #2c3e50;
    font-size: 0.9rem;

    p {
      margin: 0;
    }
  }
}

@media screen and (max-width: 800px) {
  .landingPage {
    background-size: 160%;
    background-position-x: center;
    background-position-y: 10% !important;
  }
}